import * as Noty from 'noty'
import * as _ from 'lodash'

export default options => {
	if (options.buttons) {
		options.buttons = options.buttons.map(button =>
			Noty.button(button.text, button.addClass, () => {
				button.onClick(noty)
			})
		)
	}
	const noty = new Noty(
		_.defaultsDeep(options, {
			layout: 'topRight',
			theme: 'defaultTheme',
			type: 'alert',
			text: '',
			dismissQueue: true,
			template:
				'<div class="noty_message"><span class="noty_text"></span><div class="noty_close"></div></div>',
			timeout: 5000,
			force: false,
			modal: false,
			maxVisible: 5,
			killer: false,
			closeWith: ['click'],
			callback: {},
			buttons: false,
		})
	).show()
}
