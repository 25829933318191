import { XMLHttpRequest } from 'dpack/xhr'
export function fetch(url, request?) {
	if (request) {
		request.url = url
	} else if (typeof url === 'string') {
		request = { url: url }
	} else {
		request = url
	}
	let xhr
	let requestReject, responseReject
	let requestResolved
	let promise = new Promise(function(requestResolve, reject) {
		requestReject = reject
		xhr = new XMLHttpRequest()
		let parser
		let responseResolve
		let responseRejected
		let responsePromise = new Promise(function(responseResolve, reject) {
			responseReject = reject
			xhr.addEventListener('error', responseReject)
			function receivedData() {
				if (!requestResolved) {
					requestResolved = true
					if (xhr.status) {
						let response = {
							json: function() {
								return responsePromise.then(function() {
									return JSON.parse(xhr.responseText)
								})
							},
							text: function() {
								return responsePromise.then(function() {
									return xhr.responseText
								})
							},
							dpack: function(onProgress) {
								if (onProgress) {
									xhr.addEventListener('progress', () =>
										onProgress(xhr.responseParsed, response)
									)
								}
								return responsePromise.then(function() {
									return xhr.responseParsed
								})
							},
							ok: xhr.status < 300 && xhr.status >= 200,
							status: xhr.status,
							statusText: xhr.statusText,
							responseParsed: function(options?) {
								const contentType = xhr.getResponseHeader('Content-Type')
								if (/dpack/.test(contentType)) return response.dpack(options?.onProgress)
								else if (/json/.test(contentType)) return response.json()
								else return response.text()
							},
							headers: {
								get: header => xhr.getResponseHeader(header),
							},
							xhr: xhr,
						}
						requestResolve(response)
					} else requestReject('Network error')
				}
			}
			xhr.addEventListener('progress', receivedData)
			xhr.addEventListener('load', function(event) {
				receivedData()
				responseResolve()
			})
			xhr.open(request.method || 'GET', request.url, true)
			for (let name in request.xhrFields || {}) {
				xhr[name] = request.xhrFields[name]
			}
			for (let name in request.headers) {
				xhr.setRequestHeader(name, request.headers[name])
			}
			xhr.send(request.data)
		})
	})
	promise.abort = () => {
		xhr.abort()
		if (requestResolved)
			responseReject({ status: 1, isCanceled: true, name: 'Abort', message: 'Abort response' })
		else
			requestReject({ status: 1, isCanceled: true, name: 'Abort', message: 'Abort request' })
	}
	return promise
}
