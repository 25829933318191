import LoadingDots from './components/generic/LoadingCircle';
import { get, post } from 'tools/request'
import 'style-loader!css-loader!noty/lib/noty.css'
import './pages/login.scss'
import noty from 'components/generic/noty'
window.app = {}

const submitButton = document.querySelector('#submit-login')
const loginForm = document.querySelector('#login-form')

const loadingState = () => {
	submitButton.disabled = true
	submitButton.innerHTML = ''
	submitButton.classList.add('loading')
	submitButton.appendChild(new LoadingDots)
}

loginForm.addEventListener("submit", loadingState)
loginForm.querySelector('a.login-forgot').onclick = makeForgotNotification

function makeForgotNotification() {
	const username = loginForm.querySelector('input[name=username]').value
	const forgotUrl = `https://federate.doctorevidence.com/api/forgotpassword?Username=${username}&site=docdata`
	let url
	get('/api/settings').then(settings => {
		url = settings.Settings.FederateUrlPublic+`forgotpassword?Username=${username}&site=docdata`

		noty({
			text: `<h3>Password Reset</h3>
						<p>If you forgot your password, an email can be sent to the account associated with username
						"<u>${username}</u>" containing instructions on how to reset your password.</p>`,
			layout: 'center',
			type: 'info',
			timeout: false,
			killer: true,
			buttons: [
				{
					addClass: 'btn btn-default',
					text: 'Cancel',
					onClick: $noty => $noty.close(),
				},
				{
					addClass: 'btn btn-primary',
					text: 'Send Password Reset Email',
					onClick: $noty => {
						$noty.close()
						get(url, {
							headers: {
								Accept: 'application/json',
							},
							xhrFields: {
								withCredentials: true,
							},
						}).then(data => {
							noty({
								text: `<h3>Reset Email Sent</h3>
											<p>A password reset email has been sent to the email associated with the account
											username provided. Follow the instructions in the email to reset your password.</p>`,
								layout: 'center',
								type: 'info',
								killer: true,
								timeout: 3000,
							})
						})
					},
				},
			],
		})
})
	
}
