// typecheck: ignore file
import * as _ from 'lodash'
//import app from '../app/app'
import { IReturn } from 'types/dtos'
import { fetch } from './fetch'
//import { fetch } from 'dpack/fetch' // this will use the native fetch (augmented with dpack progressive parsing)
//import { serialize } from 'dpack/lib/serialize'

const WITH_CREDS = {
	xhrFields: {
		withCredentials: true,
	},
}

const preferJSON = window.localStorage.getItem('preferJSON')
const useJSONForUpload = true

let setupDevtools

const MIME_OPTS = Object.assign(
	{
		headers: {
			Accept: 'application/json',
		},
	},
	WITH_CREDS
)

let subscriptions = []

export function renewSubscriptions() {
	// make the subscription requests again, if we need to renew the state of SSE connection
	for (let { method, path, headers } of subscriptions) {
		makeRequest(method)(path, { headers, renewing: true })
	}
}
function makeRequest(method, hasBody?, isJSON = true) {
	return function<Dto>(
		path: string,
		options?:
			| {
					data?: any
					xhr?: () => XMLHttpRequest
					headers?: { [index: string]: string }
			  }
			| IReturn<Dto>
	): Promise<Dto> {
		if (options && !options.data && !options.headers && !options.xhr) {
			options = {
				data: options,
			}
		} else {
			options = options || {}
		}

		if (hasBody) {
			options = options || {}
			if (!options.headers) {
				options.headers = {}
			}
			if (app.isDebug) {
				options.requestData = options.data
			}

			// For requests to API, we need to specify which
			// fields we're updating. See DD-6742
			if (path.toLowerCase().includes('api/') && method == 'PATCH') {
				options.data = Object.assign({}, options.data, {
					Set: _.map(options.data, (value, key) => key),
				})
				options.data = JSON.stringify(options.data)
				options.headers['Content-Type'] = 'application/json'
			} else {
				options.data = useJSONForUpload ? JSON.stringify(options.data) : serialize(options.data)
				options.headers['Content-Type'] = useJSONForUpload ? 'application/json' : 'text/dpack'
			}
		} else if (options.data) {
			let keys = Object.keys(options.data)
			if (keys.length > 0) {
				path +=
					(path.includes('?') ? '&' : '?') +
					keys.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(options.data[key]))
						.join('&')
			}
		}
		if (options?.headers?.Subscribe && !options.renewing) {
			let doSubscription = true
			if (method === 'GET') {
				if (subscriptions[path]) {
					// if we have already subscribed to this resource, don't need to do it again
					doSubscription = false
					delete options.headers.Subscribe
				} else {
					subscriptions[path] = true
				}
			}
			if (doSubscription) {
				subscriptions.push({
					method,
					path,
					headers: options.headers,
				})
			}
		}

		// Prepend API base URL if needed. Assumes we never
		// make host-relative or protocol-relative requests.
		const prependBase = path.match(/\w+:\/\//) === null

		// Strip leading slash from path because BASE_URL already has it
		path = prependBase && path[0] === '/' ? path.slice(1) : path
		const config = app?.config

		let url = prependBase ? (window.localStorage.getItem('baseURL') || '/') + path : path
		if (
			!options.noHeaders &&
			(config?.config?.PROFILING != undefined
				? config?.config?.PROFILING
				: app.isDebug)
		) {
			if (!options.headers) {
				options.headers = {}
			}
			options.headers.Profiling = true
		}

		if (app.isDebug) {
			if (!setupDevtools) {
				setupDevtools = true
				;(window.devtoolsFormatters || (window.devtoolsFormatters = [])).push({
					header(request, config) {
						if (request && request.__isLoggedRequest) {
							return [
								'span',
								{},
								request.method + ' ' + request.url + ' ' + (request.status || 'pending'),
							]
						}
						return null // ignore all other objects
					},
					hasBody(request) {
						if (request && request.__isLoggedRequest) {
							return true
						}
						return true // default
					},
					body(request) {
						var properties = [
							'ol',
							{},
							['li', {}, ['object', { object: request.headers || {} }], '(Headers)'],
						]
						if (request.requestData)
							properties.push([
								'li',
								{},
								['object', { object: request.requestData }],
								'(Request)',
							])
						if (request.responseData)
							properties.push([
								'li',
								{},
								['object', { object: request.responseData }],
								'(Response)',
							])
						if (request.xhr)
							properties.push(['li', {}, ['object', { object: request.xhr }], '(XHR)'])
						return properties
					},
				})
			}

			;(window.requests || (window.requests = [])).push(options)
			options.url = url
			options.__isLoggedRequest = true
			options.method = method
		}
		if (preferJSON) {
			MIME_OPTS.headers.Accept = 'application/json'
		}

		let fetchPromise = fetch(
			url,
			_.merge(
				{
					method,
					credentials: 'include',
					headers:
						options && options.noHeaders || !url.includes('portal')
							? {}
							: {
									'Client-Id': app.clientId,
									'sr': window.innerWidth + 'x' + window.innerHeight,
							  },
				},
				_.clone(MIME_OPTS),
				options
			)
		)
		let responsePromise = fetchPromise.then(response => {
			let result
			const contentType = response.headers.get('Content-Type')
			if (contentType) {
				if (/dpack/.test(contentType)) result = response.dpack(options.onProgress)
				else if (/json/.test(contentType)) result = response.json()
				else result = response.text()
			} else {
				result = Promise.resolve()
			}
			return result.then(data => {
				if (app.isDebug) {
					options.responseData = data
					options.status = response.status
					options.xhr = response.xhr
				}
				if (response.status >= 300) {
					const error = new Error(data?.message || data?.ResponseStatus?.Message || data)
					error.status = response.status
					error.ResponseStatus = data && data.ResponseStatus
					if (data && data.errorId) error.sourceErrorId = data.errorId
					error.data = data
					if (response.status == 401) {
						app.authentication.showLogin()
					}
					throw error
				}
				return data
			})
		})
		responsePromise.abort = fetchPromise.abort
		return responsePromise
	}
}
export const get = makeRequest('GET')
export const post = makeRequest('POST', true)
export const deleteRequest = makeRequest('DELETE', false, false)
export const put = makeRequest('PUT', true)
export const patch = makeRequest('PATCH', true)
Object.defineProperty(window, 'request', {
	value: {
		// really helpful to be able to make requests from the console
		get,
		post,
		deleteRequest,
		put,
		patch,
		set preferJSON(flag) {
			localStorage[flag ? 'setItem' : 'removeItem']('preferJSON', flag)
		},
		set server(server) {
			localStorage.setItem('baseURL', server ? 'https://' + server + '.doctorevidence.com/' : '')
		}
	},
	configurable: true,
})

const SEPARATE_NAMES = ['Search', 'Client', 'Division', 'Copy', 'Batch', 'Version', '_Fields']
function guessPathFromService(service) {
	const type = service.getTypeName()
	return (
		'api/' +
		type
			.replace(/_?[A-Z][a-z0-9]+/g, namePart =>
				SEPARATE_NAMES.includes(namePart) ? '/' + namePart + '/' : namePart
			)
			.replace(/\/\//g, '/')
	)
}

/**
 * @example
 * param({ foo: 1, bar: 'baz' })
 * -> 'foo=1&bar=baz'
 */

export function param(object: { [key: string]: string | string[] }): string {
	return Object.entries(object)
		.map(([key, value]) => {
			value = Array.isArray(value)
				? value.map(encodeURIComponent).join(',')
				: encodeURIComponent(value)

			return `${key}=${value}`
		})
		.join('&')
}
